<template>
  <figure class="highlight">
    <b-spinner label="Loading..." v-if="loading"></b-spinner>

    <b-container class="mt-6" style="padding-top: 2rem" fluid v-if="!loading">
      <h3 class="py-2">
        <span class="text-capitalize"
          >Orders Pending payment confirmation by Dealer</span
        >
        <small class="text-muted">&nbsp;&nbsp;</small>
      </h3>
      <!-- <b-alert show variant="danger" v-if="!canOrder"
        >&nbsp; {{ orderWarning }} &nbsp;
        <b-button v-b-toggle.collapse-1 variant="primary"
          >View Invoices</b-button
        ></b-alert
      >-->

      <b-alert
        :show="dismissCountDown"
        dismissible
        variant="success"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChanged"
      >
        <p>Status updated successfully</p>
        <b-progress
          variant="success"
          :max="dismissSecs"
          :value="dismissCountDown"
          height="4px"
        ></b-progress>
      </b-alert>

      <b-alert
        :show="dismissCountDownFail"
        dismissible
        variant="danger"
        @dismissed="dismissCountDown = 0"
        @dismiss-count-down="countDownChangedFail"
      >
        <p>Error updating status</p>
        <b-progress
          variant="danger"
          :max="dismissSecs"
          :value="dismissCountDownFail"
          height="4px"
        ></b-progress>
      </b-alert>

      <div>
        <b-collapse id="collapse-1" class="mt-2">
          <b-card>
            <p class="card-text">Collapse contents Here</p>
            <b-button v-b-toggle.collapse-1-inner size="sm"
              >Toggle Inner Collapse</b-button
            >
            <b-collapse id="collapse-1-inner" class="mt-2">
              <b-card>Hello!</b-card>
            </b-collapse>
          </b-card>
        </b-collapse>
      </div>

      <div class="orders">
        <p class="text-center mt-5" v-if="loading">
          <b-spinner label="Loading..."></b-spinner>
        </p>
        <div class="p-5" v-else>
          <b-nav-form>
            <b-form-input
              class="mr-sm-2"
              placeholder="Filter"
              v-model="filter"
            ></b-form-input>
            <b-button
              size="sm"
              class="my-2 my-sm-0"
              v-if="filter"
              @click="filter = ''"
              >Clear</b-button
            >
          </b-nav-form>
          <b-table
            show-empty
            small
            stacked="md"
            :items="pendingOrders"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :filter="filter"
            :filterIncludedFields="filterOn"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @filtered="onFiltered"
            class="mt-3"
          >
            <template v-slot:cell(DeliveryDate)="row">
              {{ row.value | onlyDate }}
            </template>

            <template v-slot:cell(actions)="row">
              <b-button
                size="md"
                @click="handleClick(row.item.OrderID)"
                class="mr-1 btn-black"
                variant="danger"
                >CONFIRM PAYMENT
              </b-button>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-pagination"
          ></b-pagination>

          <hr />
        </div>
        <!-- title="'Order Confirmation - ' + clickedCoupon.OrderNumber" -->
        <b-modal
          v-model="detailsModal"
          size="xl"
          :title="
            'Payment Confirmation for Order - ' + clickedCoupon.OrderNumber
          "
          hideFooter
          centered
        >
          <b-tabs v-model="tabIndex" small card>
            <b-tab title="Order Information">
              <table class="car-options mt-4">
                <!-- <tr>
                <th>Order Number</th>
                <td>:</td>
                <td>{{ clickedCoupon.OrderNumber }}</td>
                </tr>-->
                <tr>
                  <th>Order Date</th>
                  <td>:</td>
                  <td>{{ clickedCoupon.OrderDate | formatDate }}</td>
                </tr>
                <tr>
                  <th>Total Amount</th>
                  <td>:</td>
                  <td>{{ clickedCoupon.OrderTotal | amount }}</td>
                </tr>
              </table>

              <br />

              <table
                class="
                  table
                  b-table
                  cart-table
                  table-striped table-bordered table-hover
                "
              >
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Colour</th>
                    <th>Unit(s)</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    role="row"
                    v-for="(item, i) in clickedCoupon.orderDetails"
                    :key="i"
                  >
                    <td>{{ item.VehicleName }}</td>
                    <td>{{ item.Colour }}</td>
                    <td>{{ item.QuantityRequested }}</td>
                    <td>
                      {{
                        (parseFloat(item.UnitPrice) *
                          parseFloat(item.QuantityRequested))
                          | amount
                      }}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colspan="2" style="text-align: right"></th>
                    <th>{{ totalUnit }}</th>
                    <th colspan="2">{{ totalLessVATAndDiscount | amount }}</th>
                  </tr>
                  <tr>
                    <th colspan="2" style="text-align: right"></th>
                    <th>VAT</th>
                    <th colspan="2">{{ clickedCoupon.VAT | amount }}</th>
                  </tr>
                  <tr>
                    <th colspan="3">
                      <h4 style="text-align: right">Grand Total:</h4>
                    </th>
                    <th>
                      <h4>{{ clickedCoupon.OrderTotal | amount }}</h4>
                    </th>
                  </tr>
                </tfoot>
                <!---->
              </table>
              <br />
              <div class="text-right">
                <b-button variant="danger" @click="tabIndex++">Next</b-button>
              </div>
            </b-tab>
            <b-tab title="Payment Confirmation">
              <span
                class="text-danger label label-danger"
                v-if="displayErrorMessage"
              >
                Please fill all required fields with *, Documents should be PDF
                or Word
              </span>
              <table class="car-options mt-4">
                <tr>
                  <th style="width: 12%">Payment Mode *</th>
                  <td
                    v-if="
                      selectedorder.PaymentMode != 'Deposit' &&
                        selectedorder.PaymentMode != 'Credit'
                    "
                  >
                    :
                  </td>
                  <td style="width: 37%">
                    <select
                      class="mr-sm-2 form-control"
                      v-model="selectedorder.PaymentMode"
                    >
                      <option></option>
                      <option value="Deposit">My available deposit</option>
                      <option value="Transfer">Bank Transfer</option>
                      <option value="Credit" v-if="this.dealerid == 3"
                        >My available credit</option
                      >
                      <!-- <option value="Cheque">Cheque</option>
                      <option value="Draft">Draft</option>-->
                    </select>
                  </td>

                  <th
                    style="width: 12%"
                    v-if="
                      selectedorder.PaymentMode != 'Deposit' &&
                        selectedorder.PaymentMode != 'Credit'
                    "
                  >
                    Amount Paid *
                  </th>
                  <td
                    v-if="
                      selectedorder.PaymentMode != 'Deposit' &&
                        selectedorder.PaymentMode != 'Credit'
                    "
                  >
                    :
                  </td>
                  <td
                    v-if="
                      selectedorder.PaymentMode != 'Deposit' &&
                        selectedorder.PaymentMode != 'Credit'
                    "
                    style="width: 37%"
                  >
                    <b-form-input
                      placeholder="Amount Paid"
                      type="number"
                      v-model="selectedorder.AmountPaid"
                    >
                    </b-form-input>
                  </td>
                </tr>
                <tr v-if="selectedorder.PaymentMode == 'Credit'">
                  <th style="width: 12%">Available Credit Amount</th>
                  <th>
                    <span :class="[negativeBalance ? 'text-danger' : '']">{{
                      customerBalance | amount
                    }}</span>
                  </th>
                </tr>
                <tr
                  v-if="
                    selectedorder.PaymentMode != 'Deposit' &&
                      selectedorder.PaymentMode != 'Credit'
                  "
                >
                  <th>Dealer Bank *</th>
                  <td>:</td>
                  <td>
                    <select
                      class="mr-sm-2 form-control"
                      v-model="selectedorder.DealerBank"
                    >
                      <option></option>
                      <option value="Access Bank Plc">Access Bank Plc</option>
                      <option value="Citibank Nigeria Limited">
                        Citibank Nigeria Limited
                      </option>
                      <option value="Ecobank Nigeria Plc">
                        Ecobank Nigeria Plc
                      </option>
                      <option value="Fidelity Bank Plc">
                        Fidelity Bank Plc
                      </option>
                      <option value="FIRST BANK NIGERIA LIMITED">
                        FIRST BANK NIGERIA LIMITED
                      </option>
                      <option value="First City Monument Bank Plc">
                        First City Monument Bank Plc
                      </option>
                      <option value="Globus Bank Limited">
                        Globus Bank Limited
                      </option>
                      <option value="Guaranty Trust Bank Plc">
                        Guaranty Trust Bank Plc
                      </option>
                      <option value="Heritage Banking Company Ltd">
                        Heritage Banking Company Ltd
                      </option>
                      <option value="Key Stone Bank">Key Stone Bank</option>
                      <option value="Polaris Bank">Polaris Bank</option>
                      <option value="Providus Bank">Providus Bank</option>
                      <option value="Stanbic IBTC Bank Ltd">
                        Stanbic IBTC Bank Ltd
                      </option>
                      <option value="Standard Chartered Bank Nigeria Ltd">
                        Standard Chartered Bank Nigeria Ltd
                      </option>
                      <option value="Sterling Bank Plc">
                        Sterling Bank Plc
                      </option>
                      <option value="SunTrust Bank Nigeria Limited">
                        SunTrust Bank Nigeria Limited
                      </option>
                      <option value="Titan Trust Bank Ltd">
                        Titan Trust Bank Ltd
                      </option>
                      <option value="Union Bank of Nigeria Plc">
                        Union Bank of Nigeria Plc
                      </option>
                      <option value="United Bank For Africa Plc">
                        United Bank For Africa Plc
                      </option>
                      <option value="Unity Bank Plc">Unity Bank Plc</option>
                      <option value="Wema Bank Plc">Wema Bank Plc</option>
                      <option value="Zenith Bank Plc">Zenith Bank Plc</option>
                      <!-- <option value="Cheque">Cheque</option>
                      <option value="Draft">Draft</option>-->
                    </select>
                  </td>
                  <th>TNL Bank *</th>
                  <td>:</td>
                  <td>
                    <select
                      class="mr-sm-2 form-control"
                      v-model="selectedorder.TNLBank"
                    >
                      <option></option>

                      <option value="FIRST BANK NIGERIA LIMITED">
                        FIRST BANK NIGERIA LIMITED
                      </option>
                      <option value="First City Monument Bank Plc">
                        First City Monument Bank Plc
                      </option>
                      <option value="Guaranty Trust Bank Plc">
                        Guaranty Trust Bank Plc
                      </option>
                      <option value="Stanbic IBTC Bank Ltd">
                        Stanbic IBTC Bank Ltd
                      </option>
                      <option value="Wema Bank Plc">Wema Bank Plc</option>
                      <option value="Zenith Bank Plc">Zenith Bank Plc</option>
                    </select>
                  </td>
                </tr>
                <tr
                  v-if="
                    selectedorder.PaymentMode != 'Deposit' &&
                      selectedorder.PaymentMode != 'Credit'
                  "
                >
                  <th>Proof of Payment *</th>
                  <td>:</td>

                  <td>
                    <b-form-file
                      v-model="document"
                      class="mr-sm-2"
                      id="files"
                      plain
                    />
                  </td>
                  <th>Payment Details (e.g Transfer Reference) *</th>
                  <td>:</td>
                  <td>
                    <textarea
                      class="mr-sm-2 form-control"
                      rows="3"
                      v-model="selectedorder.PaymentDetails"
                    ></textarea>
                  </td>
                </tr>

                <tr>
                  <th>Additional Info</th>
                  <td
                    v-if="
                      selectedorder.PaymentMode != 'Deposit' &&
                        selectedorder.PaymentMode != 'Credit'
                    "
                  >
                    :
                  </td>
                  <td colspan="4">
                    <textarea
                      class="mr-sm-2 form-control"
                      rows="3"
                      v-model="selectedorder.AdditionalPaymentInstruction"
                    ></textarea>
                  </td>
                </tr>
              </table>

              <div class="text-left">
                <b-button variant="danger" @click="tabIndex--"
                  >Previous</b-button
                >
              </div>
              <div class="text-right">
                <b-button variant="danger" @click="saveInfo">
                  <span v-if="confirmPayment">Please wait</span>
                  <span v-else>Save</span>
                </b-button>
              </div>
            </b-tab>
          </b-tabs>
        </b-modal>
      </div>

      <!-- <div class="about">
        <h2>Pending Orders</h2>
        <div class="col-md-11">
          <table class="table table-striped table-bordered" id="orderinfo">
            <thead>
              <tr>
                <th style="width:8px;">
                  <input
                    type="checkbox"
                    class="group-checkable"
                    data-set="#orderinfo .checkboxes"
                  />
                </th>
                <th>Order Number</th>
                <th class="hidden-phone">Date</th>
                <th class="hidden-phone">Amount</th>
                <th class="hidden-phone">Time Left</th>
                <th class="hidden-phone"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in pendingOrders" v-bind:key="data.OrderID">
                <td>
                  <input type="checkbox" class="checkboxes" value="1" />
                </td>
                <td>
                  <a
                    v-on:click="chooseorder(data)"
                    href="#myModal1"
                    role="button"
                    data-toggle="modal"
                    >{{ data.OrderNumber }}</a
                  >
                </td>
                <td class="center hidden-phone">
                  {{ data.OrderDate | formatDate }}
                </td>
                <td class="hidden-phone">{{ data.OrderTotal | amount }}</td>
                <td class="hidden-phone"></td>

                <td class="hidden-phone">
                  <span class="label label-danger">Pending Payment</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>-->
    </b-container>
  </figure>
</template>

<script>
// @ is an alias to /src
//import allCars from "../data/allCars";
// eslint-disable-next-line no-unused-vars
import FileService from "../service/fileUpload";
import VehicleService from "../service/vehicle";
import { getData } from "./../utils/storage";

export default {
  data() {
    return {
      dismissSecs: 10,
      dismissCountDown: 0,
      dismissCountDownFail: 0,
      tabIndex: 0,
      fields: [
        {
          key: "sNo",
          label: "",
          sortable: true,
          sortDirection: "asc"
        },
        {
          key: "OrderNumber",
          label: "Order Number",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "OrderDate",
          label: "Order Date",
          sortable: true,
          sortDirection: "desc",
          formatter: "formatDate"
        },
        {
          key: "OrderTotal",
          label: "Amount",
          sortable: true,
          sortDirection: "desc",
          formatter: "amount"
        } /*,
        {
          key: "OwnersName",
          label: "Time Left",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "PaymentStatus",
          label: "Status",
          sortable: true,
          sortDirection: "desc"
        }*/,
        { key: "actions", label: "Actions" }
      ],
      gettingCoupons: true,
      clickedCoupon: {},
      detailsModal: false,
      currentPage: 1,
      perPage: 10,
      totalRows: 1,
      pageOptions: [5, 10, 15],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      dealerid: 0,
      pendingOrders: [],
      pendingOrderDetails: [],
      loading: false,
      selectedorder: {},
      document: null,
      confirmPayment: false,
      displayErrorMessage: false,
      canOrder: true,
      limitInfo: {},
      customerBalance: 0,
      negativeBalance: true,
      remainingLimit: 0,
      exceededLimit: false
    };
  },
  created() {
    this.hello();
    this.canOrder = getData("canOrder");
    const loggedInUser = getData("userInfo");
    this.dealerid = loggedInUser.DealerID;
    console.log("logged In User is: ", loggedInUser);
    this.getpendingOrders();
    this.getDealerLimit(loggedInUser.DealerUserID);

    if (!this.canOrder) {
      this.showToast();
    }
  },
  computed: {
    totalUnit() {
      let totalUnit = this.clickedCoupon.orderDetails
        ? this.clickedCoupon.orderDetails.reduce(
            (accumulator, currentValue) => {
              return parseInt(currentValue.QuantityRequested) + accumulator;
            },
            0
          )
        : 0;
      return totalUnit;
    },
    totalLessVATAndDiscount() {
      let totalLessVATAndDiscount = this.clickedCoupon.orderDetails
        ? this.clickedCoupon.orderDetails.reduce(
            (accumulator, currentValue) => {
              return (
                parseInt(currentValue.QuantityRequested) *
                  parseInt(currentValue.UnitPrice) +
                accumulator
              );
            },
            0
          )
        : 0;
      return totalLessVATAndDiscount;
    }
  },
  methods: {
    showToast(variant = "danger") {
      this.$bvToast.toast(
        "You have overdue invoices." + this.orderNoteWarning,
        {
          title: `Overdue Invoices`,
          variant: variant,
          solid: true,
          noAutoHide: true
        }
      );
    },
    getDealerLimit: async function(id) {
      console.log("id is: ", id);
      const { data } = await VehicleService.getCreditLimit(id);
      console.log("data is: ", data);
      this.limitInfo = data;
      let limit = 0;
      if (data.Credit_Limit_LCY >= 2000000000) {
        limit = 1000000000;
      } else {
        limit = data.Credit_Limit_LCY;
      }
      /*
      alert(limit);
      alert(data.Balance);
*/
      this.customerBalance = limit - data.Balance;
      if (limit <= data.balance) this.negativeBalance = true;

      //return data;
    },
    getpendingOrders: async function() {
      this.loading = true;
      const { data } = await VehicleService.getPendingOrders(this.dealerid);
      this.loading = false;
      this.pendingOrders = data.Data.Orders;
      this.pendingOrderDetails = data.Data.OrderDetails;
      this.totalRows = this.pendingOrders.length;
    },
    handleClick(id) {
      this.clickedCoupon = this.pendingOrders.filter(n => n.OrderID === id)[0];
      this.clickedCoupon.orderDetails = this.pendingOrderDetails.filter(
        n => n.OrderID === id
      );
      console.log(this.clickedCoupon);
      this.selectedorder.OrderID = id;
      this.selectedorder.PaymentStatus = 0;
      this.detailsModal = true;
      this.tabIndex = 0;

      const ordervalue = this.clickedCoupon.OrderTotal;
      const balance = this.limitInfo.Credit_Limit_LCY - this.limitInfo.Balance;

      if (balance < ordervalue) {
        this.exceededLimit = true;
      } else {
        this.exceededLimit = false;
      }
    },
    removeOrder(orderid) {
      let orders = this.pendingOrders;
      this.pendingOrders = orders.filter(function(obj) {
        return obj.OrderID !== orderid;
      });
    },
    saveInfo: async function() {
      this.displayErrorMessage = false;
      if (!this.checkPaymentForm()) {
        this.displayErrorMessage = true;
        return;
      }
      this.confirmPayment = true;
      if (
        this.selectedorder.PaymentMode != "Deposit" &&
        this.selectedorder.PaymentMode != "Credit"
      ) {
        //only upload proof if its not deposit
        if (this.checkFileRule(this.document)) {
          const formData = new FormData();
          formData.append(`document`, this.document);
          const uploadresponse = await FileService.uploadFile(formData);
          console.log("upload response path is:", uploadresponse);
          this.selectedorder.PaymentProofURL = uploadresponse
            ? uploadresponse.data.Path
            : "";
        }
      }
      console.log("Selected Order: ", this.selectedorder);
      try {
        if (
          this.selectedorder.PaymentMode == "Deposit" ||
          this.selectedorder.PaymentMode == "Credit"
        ) {
          this.selectedorder.DealerBank = "";
          this.selectedorder.TNLBank = "";
          this.selectedorder.AmountPaid = "0";
          this.selectedorder.PaymentDetails = "";
          this.selectedorder.PaymentProofURL = "";
        }

        const { data } = await VehicleService.updateOrderStatus(
          this.selectedorder
        );
        if (data.Data) {
          this.detailsModal = false;
          this.showSuccessAlert();
          this.removeOrder(this.selectedorder.OrderID);
          this.selectedorder = {};
          this.confirmPayment = false;
        } else {
          this.showFailureAlert();
        }
        console.log("data: ", data);
      } catch (ex) {
        this.showFailureAlert();
      }
      this.loading = false;
      // this.detailsModal = false;
    },
    checkPaymentForm() {
      if (!this.selectedorder) {
        return false;
      }
      if (
        this.selectedorder.PaymentMode == "Deposit" ||
        this.selectedorder.PaymentMode == "Credit"
      )
        return true;

      if (
        !this.selectedorder.PaymentMode ||
        !this.selectedorder.AmountPaid ||
        !this.selectedorder.DealerBank ||
        !this.selectedorder.TNLBank
      ) {
        return false;
      }
      return true;
    },
    checkFileRule(file) {
      return /\.(pdf|doc|docx|PDF|DOC|DOCX)$/.test(file.name);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    countDownChangedFail(dismissCountDown) {
      this.dismissCountDownFail = dismissCountDown;
    },
    showSuccessAlert() {
      this.dismissCountDown = this.dismissSecs;
    },
    showFailureAlert() {
      this.dismissCountDownFail = this.dismissSecs;
    }
  },
  filters: {},
  watch: {}
};
</script>
<style scoped>
.wizard-navigation .stepTitle span {
  font-size: 2rem !important;
  font-weight: 500;
  line-height: 1.2;
}

.mt-6 {
  margin-top: 4rem !important;
}
</style>

.wizard-navigation .stepTitle span { font-size: 2rem !important; font-weight:
500; line-height: 1.2; } .mt-6 { margin-top: 4rem !important; }
